// ** Logo
const SpinnerComponent = () => (
  <div className="fallback-spinner vh-100">
    <div className="loading">
      <div className="effect-1 effects" />
      <div className="effect-2 effects" />
      <div className="effect-3 effects" />
    </div>
  </div>
);

export default SpinnerComponent;
